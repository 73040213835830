





























import Vue from "vue";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import SettingItemWrap from "@monorepo/uikit/src/components/Settings/SettingItemWrap.vue";
import StructureSettings from "../info/StructureSettings.vue";
import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import { INotificationSetting } from "@monorepo/utils/src/types/INotificationSetting";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export default defineComponent({
  name: "NotificationsView",
  components: {
    CheckboxElement,
    SettingItemWrap,
    StructureSettings,
  },
  data() {
    return {
      mailSettings: [] as INotificationSetting[],
      mailValues: {} as Record<string, boolean>,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("auth", ["getUserMailNotifications", "saveUserMailNotifications"]),
    async saveMailSettings(value: boolean, setting: INotificationSetting) {
      Vue.set(setting, "isEnabled", value);

      const result =
        this.mailSettings?.map((item) => {
          return {
            notificationTemplate: item.notificationTemplate?.code,
            isEnabled: item.notificationTemplate?.code !== setting.notificationTemplate?.code ? item.isEnabled : value,
          };
        }) ?? [];

      const isSaved = await this.saveUserMailNotifications(result);
      if (isSaved) {
        showNotification("Настройки успешно изменены", NOTIFICATION_STATUS.SUCCESS);
      } else {
        showNotification("Ошибка изменения настроек");
      }
    },
    async getSettings() {
      this.isLoading = true;

      const mailSettings = await this.getUserMailNotifications().catch(() => []);
      this.mailSettings = mailSettings;

      this.mailValues =
        this.mailSettings?.reduce((acc, item) => {
          acc[item.notificationTemplate.code] = item.isEnabled;
          return acc;
        }, {} as Record<string, boolean>) ?? [];

      this.isLoading = false;
    },
  },
  async mounted() {
    await this.getSettings();
  },
});
